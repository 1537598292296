window.addEventListener("load", function () {
  function iOs() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  function check_menu_color(){
    let scrollPosition =
    window.pageYOffset ||
    window.scrollY ||
    document.body.scrollTop ||
    document.documentElement.scrollTop;

    let l_accueil = $('#accueil').height();
    let l_about = l_accueil + $('#qui_suis_je').height();
    let l_rdv = l_about + $('#rendez_vous').height();
    let l_avis = l_rdv + $('#avis').height();

    if (scrollPosition >= window.innerHeight) {
      if ($("#menu_ul").hasClass("mano_dark_menu_ul")) {
        $(".mano_navbar").css("background-color", "#f8ddc5");
        $(".mano_checkbtn > svg").css("fill", "#222");
        $("#menu_ul").removeClass("mano_dark_menu_ul");
        $("#menu_ul").addClass("mano_menu_ul");
        $(".mano_navbar_logo").attr(
          "src",
          "/assets/clients/manolita/logo_light_bg.png"
        );
      }
    } else {
      if ($("#menu_ul").hasClass("mano_menu_ul")) {
        $(".mano_navbar").css("background-color", "rgba(0, 0, 0, 0.3)");
        $(".mano_checkbtn > svg").css("fill", "#fff");
        $("#menu_ul").removeClass("mano_menu_ul");
        $("#menu_ul").addClass("mano_dark_menu_ul");
        $(".mano_navbar_logo").attr(
          "src",
          "/assets/clients/manolita/logo_dark_bg.png"
        );
      }
    }

    if (scrollPosition <= l_accueil) {
      $(".single_page_link").removeClass("active-nav-link");
      $('span[data-target="accueil"]').addClass("active-nav-link");
    }
    else if(scrollPosition > l_accueil && scrollPosition <= l_about){
      $(".single_page_link").removeClass("active-nav-link");
      $('span[data-target="qui_suis_je"]').addClass("active-nav-link");
    }
    else if(scrollPosition > l_about && scrollPosition <= l_rdv){
      $(".single_page_link").removeClass("active-nav-link");
      $('span[data-target="rendez_vous"]').addClass("active-nav-link");
    }
    else if(scrollPosition > l_rdv && scrollPosition <= l_avis){
      $(".single_page_link").removeClass("active-nav-link");
      $('span[data-target="avis"]').addClass("active-nav-link");
    }
  }

  if (iOs()) {
    $("#manolita_main > #accueil").css("background-attachment", "scroll");
    $("#manolita_main > #rendez_vous").css("background-attachment", "scroll");
  }

  check_menu_color();

  $("#manolita_main > #accueil").css("min-height", window.innerHeight);
  $("#manolita_main > #rendez_vous").css("min-height", window.innerHeight);


  window.addEventListener("resize", (event) => {
    $("#manolita_main > #accueil").css("min-height", window.innerHeight);
    $("#manolita_main > #rendez_vous").css("min-height", window.innerHeight);
  });
  
  window.addEventListener("scroll", function (e) {
    let scrollPosition =
      window.pageYOffset ||
      window.scrollY ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;

      let l_accueil = $('#accueil').height();
      let l_about = l_accueil + $('#qui_suis_je').height();
      let l_rdv = l_about + $('#rendez_vous').height();
      let l_avis = l_rdv + $('#avis').height();

  
    if (scrollPosition >= window.innerHeight) {
      if ($("#menu_ul").hasClass("mano_dark_menu_ul")) {
        $(".mano_navbar").css("background-color", "#f8ddc5");
        $(".mano_checkbtn > svg").css("fill", "#222");
        $("#menu_ul").removeClass("mano_dark_menu_ul");
        $("#menu_ul").addClass("mano_menu_ul");
        $(".mano_navbar_logo").attr(
          "src",
          "/assets/clients/manolita/logo_light_bg.png"
        );
      }
    } else {
      if ($("#menu_ul").hasClass("mano_menu_ul")) {
        $(".mano_navbar").css("background-color", "rgba(0, 0, 0, 0.3)");
        $(".mano_checkbtn > svg").css("fill", "#fff");
        $("#menu_ul").removeClass("mano_menu_ul");
        $("#menu_ul").addClass("mano_dark_menu_ul");
        $(".mano_navbar_logo").attr(
          "src",
          "/assets/clients/manolita/logo_dark_bg.png"
        );
      }
    }

    if (scrollPosition <= l_accueil) {
      $(".single_page_link").removeClass("active-nav-link");
      $('span[data-target="accueil"]').addClass("active-nav-link");

      $('#mano_check').on('change', function() {
        if ($(this).is(':checked') && scrollPosition < window.innerHeight) {
            $('.mano_navbar').css({'backgroundColor': 'rgba(0,0,0,0.8)'});
        } else {
            $('.mano_navbar').css({'backgroundColor': 'rgba(0,0,0,0.3)'});
        }
      });
    }
    else if(scrollPosition > l_accueil && scrollPosition <= l_about){
      $(".single_page_link").removeClass("active-nav-link");
      $('span[data-target="qui_suis_je"]').addClass("active-nav-link");
      $('#mano_check').off('change');
    }
    else if(scrollPosition > l_about && scrollPosition <= l_rdv){
      $(".single_page_link").removeClass("active-nav-link");
      $('span[data-target="rendez_vous"]').addClass("active-nav-link");
      $('#mano_check').off('change');
    }
    else if(scrollPosition > l_rdv && scrollPosition <= l_avis){
      $(".single_page_link").removeClass("active-nav-link");
      $('span[data-target="avis"]').addClass("active-nav-link");
      $('#mano_check').off('change');
    }


    

  });
});
